import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Logo from "../../../assets/tag8logoblack.png";
// import CatDog from "../../../assets/generic_img.jpg";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import "../founddetails/founddetails.component.css";
import axios from "axios";
import { Link } from "react-router-dom";
import html2canvas from "html2canvas";
import Loader from "../../../assets/loader.gif";
import { switchUrls } from "../../../api/index.js";

const baseURL = switchUrls("generic");
export default class FoundDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      owner_firstname: "",
      owner_lastname: "",
      valuable_type: "",
      valuable_desc: "",
      owner_phone: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      address: "",
      passport_imagelocation: "",
      showLoader: true,
    };
  }

  componentDidMount() {
    axios
      .get(
        `${baseURL}/api/generic/getreportlocation/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log(response);
        this.setState({
          address: response.data.data[0].address,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/generic/getGenericDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log(response);
        this.setState({
          generic_imagelocation: response.data.generic_imagelocation,
          valuable_type: response.data.valuable_type,
          valuable_desc: response.data.valuable_desc,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/generic/getReportLost/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log(response);
        this.setState({
          owner_firstname: response.data.owner_firstname,
          owner_lastname: response.data.owner_lastname,
          valuable_type: response.data.valuable_type,
          valuable_type: response.data.valuable_type,
          valuable_desc: response.data.valuable_desc,
          owner_phone: response.data.owner_phone,
          lost_text: response.data.lost_text,
          lost_date: response.data.lost_date,
          reward: response.data.reward,
          rewardcurrency: response.data.rewardcurrency,
          lost_timeone: response.data.lost_timeone,
          lost_timetwo: response.data.lost_timetwo,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
  }
  render() {
    const {
      generic_imagelocation,
      owner_firstname,
      owner_lastname,
      valuable_type,
      valuable_desc,
      address,
      owner_phone,
      reward,
      rewardcurrency,
      lost_date,
    } = this.state;

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <div>
        <div
          className="row text-center pt-4"
          style={{ width: "60%", marginLeft: "20%" }}
        >
          <div className="col">
            <Button
              variant="contained"
              className="bg-warning postar-res button-styling mt-3"
              onClick={this.downloadposter}
              style={{ width: "80%" }}
            >
              Download
            </Button>
          </div>
          <div className="col">
            <a
              href={"/edit-report-lost/" + this.props.match.params.tag_number}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                className="bg-warning postar-res button-styling mt-3"
                style={{ width: "80%" }}
              >
                Edit
              </Button>
            </a>
          </div>
          <div className="col">
            <Button
              variant="contained"
              onClick={() => {
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              }}
              className="bg-warning postar-res button-styling mt-3"
              style={{ width: "80%" }}
            >
              Go To My Tags
            </Button>
          </div>
        </div>

        <div id="contentToConvert" className="py-3">
          <Card className="mb-5 mat-card">
            <div className="row mt-4 text-center">
              <div className="col-md-4 text-center">
                {generic_imagelocation ? (
                  <img
                    src={generic_imagelocation}
                    className="text-center"
                    alt="pet"
                    style={{
                      width: "90%",
                      height: "250px",
                      borderRadius: 50,
                      marginTop: 7,
                    }}
                  />
                ) : (
                  <img
                    src={
                      "https://storage.googleapis.com/pettag/qr/generic_img.jpg"
                    }
                    alt="photo"
                    className="text-center"
                    style={{
                      width: "100%",
                      height: "400px",
                      borderRadius: 50,
                      marginTop: 7,
                    }}
                  />
                )}
              </div>
              <div className="col-md-8">
                <div className="row mt-5">
                  {owner_phone != "" ? (
                    <div className="col text-center">
                      <p className="header-font lost-info-header mb-5">
                        Contact Number of Generic Owner Is
                      </p>
                      <p className="text-center lost-info-text font-weight-bold ">
                        {owner_phone}
                      </p>
                    </div>
                  ) : (
                    <div className="col text-center">
                      <p className="header-font lost-info-header mb-5">
                        This Passport is not reported as Lost
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4 ml-5 marginleftmove">
              <div className="col">
                <p className="header-font lost-info-header">
                  Owner ID: <span>{this.props.match.params.tag_number}</span>
                </p>
              </div>
              <div className="col">
                <p className="header-font lost-info-header">
                  Valuable: <span>{valuable_type}</span>
                </p>
              </div>
            </div>
            {valuable_desc ? (
              <div className="row mt-4 ml-5 marginleftmove1">
                <p className="header-font lost-info-header ml-3">
                  Valuable Description <span>{valuable_desc}</span>
                </p>
              </div>
            ) : (
              <div></div>
            )}
            {address ? (
              <div className="row mt-4 ml-5 marginleftmove1">
                <p className="header-font lost-info-header ml-3">
                  Address <span>{address}</span>
                </p>
              </div>
            ) : (
              <div></div>
            )}

            <hr />
            <div className="row text-center">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/tag8logoblack.png"
                }
                className="mb-3 text-center"
                width="100px"
                height="auto"
                alt=""
                style={{ margin: "auto" }}
              />
            </div>
          </Card>
        </div>
        {this.state.showLoader && (
          <div className="loader-container">
            <div className="loader">
              <img
                src={Loader}
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
