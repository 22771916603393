import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Logo from "../../assets/genielogofinal.png";
// import CatDog from "../../assets/generic_img.jpg";
// import bottle from "../../assets/plastic-bottle.png";
// import mouse from "../../assets/mouse.png";
// import watch from "../../assets/watch.png";
// import bag from "../../assets/bag.png";
// import schoolbag from "../../assets/schoolbag.png";
// import sportbag from "../../assets/Untitled design.png";
// import handbag from "../../assets/handbag.png";
// import charger from "../../assets/charger.png";
// import mobile from "../../assets/smartphone.png";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import "./genericposter.component.css";
import axios from "axios";
import { Link } from "react-router-dom";
import html2canvas from "html2canvas";
import Navbar from "../header/header.component.jsx";
import Loader from "../../assets/loader.gif";
import { switchUrls } from "../../api/index.js";
import * as GiIcons from "react-icons/gi";

const baseURL = switchUrls("generic");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default class PetPoster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      owner_firstname: "",
      owner_lastname: "",
      valuable_type: "",
      valuable_desc: "",
      owner_phone: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      address: "",
      generic_imagelocation: "",
      active: false,
      showLoader: true,
    };
  }

  downloadposter() {
    let elem = document.getElementById("contentToConvert");
    html2canvas(elem).then(function (canvas) {
      let generatedImage = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let a = document.createElement("a");
      a.href = generatedImage;
      a.download = `passport-poster.png`;
      a.click();
    });
  }
  toggleMenu() {
    console.log(this.state.active);

    this.setState({
      active: !this.state.active,
    });
  }

  componentDidMount() {
    const access_token = usertoken;

    const getURL =
      `${baseURL}/api/generic/getreportlocation/` +
      this.props.match.params.tag_number;
    axios
      .get(getURL, {
        headers: {
          Authorization: access_token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({
          address: response.data.data[0].address,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/generic/getgenericImagePoster/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        this.setState({
          pet_imagelocation: response.data.pet_imagelocation,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        `${baseURL}/api/generic/getReportLost/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log(response);
        const { valuable_type } = response.data;
        let valuableImage;
        switch (valuable_type) {
          case "WaterBottle":
            valuableImage =
              "https://storage.googleapis.com/pettag/qr/plastic-bottle.png";
            break;
          case "Mouse":
            valuableImage =
              "https://storage.googleapis.com/pettag/qr/mouse.png";
            break;
          case "Watch":
            valuableImage =
              "https://storage.googleapis.com/pettag/qr/watch.png";
            break;
          case "LaptopBag":
            valuableImage = "https://storage.googleapis.com/pettag/qr/bag.png";
            break;
          case "SchoolBag":
            valuableImage =
              "https://storage.googleapis.com/pettag/qr/schoolbag.png";
            break;
          case "SportsKitBag":
            valuableImage =
              "https://storage.googleapis.com/pettag/qr/sport-bag.png";
            break;
          case "LasiesPurse":
            valuableImage =
              "https://storage.googleapis.com/pettag/qr/handbag.png";
            break;
          case "LaptopCharger":
            valuableImage =
              "https://storage.googleapis.com/pettag/qr/charger.png";
            break;
          case "Mobile":
            valuableImage =
              "https://storage.googleapis.com/pettag/qr/smartphone.png";
            break;
          case "Laptop":
            valuableImage =
              "https://storage.googleapis.com/pettag/qrtagdev/assets/laptop.png";
          case "Camera":
            valuableImage =
              "https://storage.googleapis.com/pettag/qrtagdev/assets/ic_camer.png";
          default:
            valuableImage = null;
            break;
        }

        this.setState({
          owner_firstname: response.data.owner_firstname,
          owner_lastname: response.data.owner_lastname,
          valuable_type: valuable_type,
          valuableImage: valuableImage,
          valuable_desc: response.data.valuable_desc,
          address: response.data.address,
          owner_phone: response.data.owner_phone,
          lost_text: response.data.lost_text,
          lost_date: response.data.lost_date,
          reward: response.data.reward,
          rewardcurrency: response.data.rewardcurrency,
          lost_timeone: response.data.lost_timeone,
          lost_timetwo: response.data.lost_timetwo,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
  }

  render() {
    const {
      owner_firstname,
      owner_lastname,
      pet_imagelocation,
      valuable_type,
      valuableImage,
      valuable_desc,
      address,
      lost_date,
      owner_phone,
      reward,
      rewardcurrency,
      active,
    } = this.state;

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <div>
        <div className="genie-nav" id="genie-nav">
          <img
            src={"https://storage.googleapis.com/pettag/qr/tag8logoblack.png"}
            alt="logo"
            style={{ width: "5rem" }}
          />

          <button
            className="bag-poster-toggle-btn"
            onClick={() => this.toggleMenu()}
          >
            <GiIcons.GiHamburgerMenu />
          </button>
        </div>

        <div
          className={
            active ? "poster-utility" : "poster-utility poster-utility-hide"
          }
        >
          {/* <button className="poster-utility-btns" onClick={this.downloadposter}>
            Download
          </button> */}

          <a
            className="poster-utility-btns"
            href={
              "/#/generic/edit-report-lost/" +
              this.props.match.params.tag_number
            }
          >
            Edit
          </a>

          <a
            onClick={() => {
              window.location.href =
                "/dashboard/" + localStorage.getItem("uid");
            }}
            className="poster-utility-btns"
          >
            Go To My Tags
          </a>
        </div>

        <div id="contentToConvert" className="py-3">
          <Card className="mb-5 poster-mat-card">
            <div className="row text-center">
              {valuable_type ? (
                <p className="header-font lost-valuable header-text mt-5">
                  LOST {valuable_type.toUpperCase()} !
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col-md-5 text-center">
                {pet_imagelocation ? (
                  <img
                    alt=""
                    src={`data:image/png;base64,${pet_imagelocation}`}
                    className="mat-card-image pt-5 pl-5"
                    style={{ width: "100%" }}
                  />
                ) : (
                  valuableImage && (
                    <img
                      alt=""
                      className="mat-card-image pt-5 pl-5 bordered-image"
                      src={valuableImage}
                      // style={{ width: "100%" }}
                    />
                  )
                )}
              </div>
              <div className="col-md-7">
                <div className="firstbox mt-5">
                  {valuable_desc ? (
                    <div className="col text-center">
                      <div className="first-box">
                        <p
                          className="header-font lost-info-header-desc"
                          // style={{ marginBottom: "20px" }}
                        >
                          DESCRIPTION
                        </p>
                        <p className="text-center lost-info-text">
                          {valuable_desc}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {valuable_type ? (
                    <div className="col text-center">
                      <div className="first-box">
                        <p
                          className="header-font lost-info-header-desc"
                          // style={{ marginBottom: "20px" }}
                        >
                          GENERIC VALUABLE
                        </p>
                        <p className="text-center lost-info-text">
                          {toTitleCase(valuable_type)}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <br />
            <div className="help-div">
              <div className="row">
                <div className="col-md-6">
                  <div className="left-column box">
                    <p className="bottom-text-1 mt-4">PLEASE</p>
                    <p className="bottom-text-1">
                      <span className="bottom-help-text">HELP US</span>
                      FIND
                    </p>
                    <p className="bottom-text-1">{valuable_type}</p>
                    {owner_phone && (
                      <div>
                        <p className="answers-to-name-text-1 header-font text-center">
                          Call: {owner_firstname} {owner_lastname}
                        </p>
                        <p className="answers-to-name-text-1 header-font text-center">
                          {owner_phone}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {rewardcurrency && reward && (
                  <div className="col-md-6">
                    <div className="right-column box">
                      <p className="header-font reward-header bottom-text-res">
                        REWARD
                      </p>
                      <p className="bottom-text bottom-text-res mt-2">
                        {rewardcurrency} {reward}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <hr /> */}
          </Card>
        </div>
        {this.state.showLoader && (
          <div className="loader-container">
            <div className="loader">
              <img
                src={Loader}
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
