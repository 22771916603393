import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../reportlost.component.css";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Navbar from "../../header/header.component.jsx";
import IconButton from "@material-ui/core/IconButton";
import MapContainer from "../testMap";
import "date-fns";
import moment from "moment";
import {
  TextareaAutosize,
  // Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import Userimage from "../../../assets/user2.png";
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import Loader from "../../../assets/loader.gif";
import { switchUrls } from "../../../api/index";

const baseURL = switchUrls("generic");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");
const Name = localStorage.getItem("name");
let token = usertoken;

function getSteps() {
  return ["Loss Details", "Location", "Date & Time of Loss", "Generic Details"];
}

const steps = getSteps();

export default class EditReportLost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebar: true,
      subMenu: true,
      alignment: "left",
      tag_number: "",
      lf_status: "lost",
      valuable_type: "",
      valuable_desc: "",
      owner_firstname: "",
      owner_lastname: "",
      owner_phone: "",
      owner_altphone: "",
      owner_email: "",
      owner_altemail: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      lost_edittimeone: "",
      lost_edittimetwo: "",
      isFromTimeLessThanToTime: false,
      isEndTimeButtonDisabled: false,
      latitude: "",
      longitude: "",
      editlocation: false,
      snackbaropen: false,
      snackbarmsg: "",
      activeStep: 0,
      showLoader: true,
    };

    // this.handleDropdownChange = this.handleDropdownChange.bind(this);
    // this.getCoordinates = this.getCoordinates.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
  }
  parseTimeStringToTimeObject(timeStr) {
    if (typeof timeStr !== "string" || timeStr.trim().length === 0) {
      console.error("Invalid time format:", timeStr);
      return null;
    }
    // console.log(timeStr, "stringtime");
    const timeFormat = "hh:mm A";

    try {
      const parsedTime = moment(timeStr, timeFormat);
      return parsedTime.isValid() ? parsedTime.toDate() : null;
    } catch (error) {
      console.error("Invalid time format:", timeStr);
      return null;
    }
  }
  handleAlignment = (event, alignment) => this.setState({ alignment });

  // handleDropdownChange(event) {
  //   this.setState({ valuable_type: event.target.value });
  //   if (event.target.value === "Car or Bike Keys") {
  //     document.getElementById("carorbikekeysselect").style.display = "block";
  //     document.getElementById("otherkeysselect").style.display = "none";
  //   } else if (event.target.value === "Other Keys") {
  //     document.getElementById("carorbikekeysselect").style.display = "none";
  //     document.getElementById("otherkeysselect").style.display = "block";
  //   }
  // }

  componentDidMount() {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(this.getCoordinates);
    // } else {
    //   alert("Geolocation is not supported by this browser.");
    // }

    if (this.state.valuable_type === "Car or Bike Keys") {
      document.getElementById("carorbikekeysselect").style.display = "block";
      document.getElementById("otherkeysselect").style.display = "none";
    } else if (this.state.valuable_type === "Other Keys") {
      document.getElementById("carorbikekeysselect").style.display = "none";
      document.getElementById("otherkeysselect").style.display = "block";
    }

    axios
      .get(
        `${baseURL}/api/generic/getReportLost/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log(response);
        const date = new Date(response.data.lost_date);
        const timeOne = response.data.lost_timestart;
        const timeTwo = response.data.lost_timeend;
        this.setState({
          tag_number: response.data.tag_number,
          valuable_type: response.data.valuable_type,
          valuable_desc: response.data.valuable_desc,
          owner_firstname: response.data.owner_firstname,
          owner_lastname: response.data.owner_lastname,
          owner_phone: response.data.owner_phone,
          owner_altphone: response.data.owner_altphone,
          owner_email: response.data.owner_email,
          owner_altemail: response.data.owner_altemail,
          lost_text: response.data.lost_comment,
          lost_date: date,
          lost_timeone: timeOne,
          lost_timetwo: timeTwo,
          reward: response.data.reward,
          rewardcurrency: response.data.rewardcurrency,
          // lost_timeone: response.data.lost_timestart,
          // lost_timetwo: response.data.lost_timeend,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  sendlatitude = (data) => {
    console.log(data);
    this.setState({
      latitude: data.lat,
      longitude: data.lng,
    });
  };
  // getCoordinates(position) {
  //   console.log(position);
  //   this.setState({
  //     latitude: position.coords.latitude,
  //     longitude: position.coords.longitude,
  //   });
  // }
  handleDateChange = (date) => {
    console.log(date, "dates");
    const formattedDate = date.toLocaleDateString();
    console.log(formattedDate, "dates");
    this.setState({ lostedit_date: formattedDate });
  };
  handleFromTimeChange = (time) => {
    console.log(time, "time one");
    const formattedTime = time.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    console.log(formattedTime, "timeone");
    // const selectedTimeOne = new Date(formattedTime);
    this.setState({ lost_edittimeone: formattedTime });
  };
  handleToTimeChange = (time) => {
    console.log(time, "time two");
    const formattedTime = time.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    console.log(formattedTime, "timetwo");

    // const selectedTimeTwo = new Date(formattedTime);
    this.setState({ lost_edittimetwo: formattedTime });
  };
  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };
  compareTimes = () => {
    const { lost_timeone, lost_timetwo } = this.state;
    const currentDate = new Date();

    console.log(lost_timeone, "lostTime one");
    console.log(lost_timetwo, "lostTime two");

    if (lost_timeone && lost_timetwo) {
      const fromTime = new Date(
        `${currentDate.toDateString()} ${lost_timeone}`
      );
      const toTime = new Date(`${currentDate.toDateString()} ${lost_timetwo}`);

      console.log(fromTime, toTime, "date1date2");
      if (fromTime > toTime) {
        console.log("From Time is greater than To Time");
        this.setState({
          isFromTimeLessThanToTime: false,
          isEndTimeButtonDisabled: false,
        });
      } else {
        this.setState(
          {
            isFromTimeLessThanToTime: true,
            isEndTimeButtonDisabled: true,
          },
          () => {
            setTimeout(() => {
              this.setState({ isFromTimeLessThanToTime: false });
            }, 3000);
          }
        );
        console.log("From Time is less than To Time");
      }
    }
  };
  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    axios
      .post(
        `${baseURL}/api/generic/editReportLost/` +
          this.props.match.params.tag_number,
        this.state,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        console.log(response);
        if (response.data === "Report Lost Details Updated") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
          window.location.href =
            "/#/Generic-poster/" + this.props.match.params.tag_number;
        } else if (response.data === "Report Lost Details Not Updated") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Report Lost Details Not Updated",
        });
      });
  };
  getContainerStyle = () => {
    const { activeStep } = this.state;
    if (activeStep === 2) {
      return { height: "93vh" };
    } else if (activeStep === 3) {
      return { height: "116vh" };
    } else {
      return { height: "93vh" };
    }
  };
  getStepContent = (stepIndex) => {
    const {
      lost_text,
      lost_date,
      latitude,
      longitude,
      lost_timeone,
      lost_timetwo,
      tag_number,
      valuable_type,
      valuable_desc,
      passport,
      Surname,
      givenName,
      nationality,
      placeofissue,
      address,
      dateofissue,
      validityperiod,
      owner_firstname,
      owner_lastname,
      owner_phone,
      owner_altphone,
      owner_email,
      owner_altemail,
      reward,
      rewardcurrency,
      editlocation,
    } = this.state;
    let timeOneValue = this.state.lost_timeone;
    let timeTwoValue = this.state.lost_timetwo;

    if (moment(this.state.lost_timeone, moment.ISO_8601, true).isValid()) {
      timeOneValue = moment(this.state.lost_timeone).format("hh:mm A");
    }
    if (moment(this.state.lost_timetwo, moment.ISO_8601, true).isValid()) {
      timeTwoValue = moment(this.state.lost_timetwo).format("hh:mm A");
    }

    const timeOneDate = this.parseTimeStringToTimeObject(timeOneValue);
    const timeTwoDate = this.parseTimeStringToTimeObject(timeTwoValue);

    // console.log(timeOneDate, "timeOneDate");
    // console.log(timeTwoDate, "timeTwoDate");

    switch (stepIndex) {
      case 0:
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "whitesmoke",
              }}
            >
              <TextareaAutosize
                rows={8}
                className="loss-details-body text-center p-3"
                // className="custom-textfield"
                variant="outlined"
                // InputProps={{ disableUnderline: true }}
                style={{
                  fontSize: "16px",
                  borderColor: "gray",
                  // marginTop: "5rem",
                  marginLeft: "0px",
                  width: "100%",
                  padding: "8px",
                  resize: "none",
                }}
                name="lost_text"
                label="Lost Text"
                placeholder="Give a brief account of loss."
                value={lost_text}
                onChange={this.changeHandler}
              />
              <div className="row mt-5 mb-4">
                <div className="col">
                  <Button
                    type="button"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                    variant="contained"
                    style={{ width: "200px" }}
                    className="submit-login cancel-btn mt-2"
                  >
                    CANCEL
                  </Button>
                </div>
                <div className="col">
                  <Button
                    variant="contained"
                    className="bg-warning nextback nextbutton mt-2"
                    onClick={this.handleNext}
                    style={{ width: "200px" }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "whitesmoke",
            }}
          >
            <div style={{ "margin-left": "15%" }}>
              <div className="row">
                <MapContainer
                  sendlatitude={this.sendlatitude}
                  lostlatitude={this.state.latitude}
                  lostlongitude={this.state.longitude}
                  editlocation={this.state.editlocation}
                />
              </div>
            </div>

            <div className="reportlost-location-input-cnt">
              <div className="reportlost-location-input-cnt-lat">
                <TextField
                  type="text"
                  // className="bagactivation-input-tag"
                  variant="outlined"
                  label="latitude"
                  name="latitude"
                  value={latitude}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="reportlost-location-input-cnt-lon">
                <TextField
                  type="text"
                  // className="bagactivation-input-tag"
                  variant="outlined"
                  label="longitude"
                  name="longitude"
                  value={longitude}
                  onChange={this.changeHandler}
                />
              </div>
            </div>

            <div className="reportlost-location-btns">
              <Button variant="contained" onClick={this.handleBack}>
                Back
              </Button>

              <Button
                variant="contained"
                value="right"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>

              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="date-time-loss">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "whitesmoke",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date and Time of Loss"
                  onChange={(date) => this.setState({ lost_date: date })}
                  defaultValue={lost_date}
                  disableFuture
                  // value={}
                  // maxDate={maxSelectableDate}
                  // renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div>
              <div class="time-picker-container">
                <div>
                  {/* <label>From Time of Loss :</label> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      // onChange={this.handleFromTimeChange}
                      label="From Time"
                      timezone="system"
                      onChange={(time) => {
                        const formattedTime = moment(time).format("hh:mm A");
                        this.setState({ lost_timeone: formattedTime }, () => {
                          this.compareTimes();
                        });
                      }}
                      value={timeOneDate}
                    />
                  </LocalizationProvider>
                </div>
                <span>
                  <label style={{ marginTop: "1rem" }}>-</label>
                </span>
                <div>
                  {/* <label>To Time of Loss :</label> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label="To Time"
                      timezone="system"
                      onChange={(time) => {
                        const formattedTime = moment(time).format("hh:mm A");
                        this.setState({ lost_timetwo: formattedTime }, () => {
                          this.compareTimes();
                        });
                      }}
                      value={timeTwoDate}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              {this.state.isFromTimeLessThanToTime && (
                <p style={{ color: "red" }}>
                  From Time must be less than To Time
                </p>
              )}
            </div>
            <div className="date-time-loss-btns">
              {/* <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
                style={{ width: "200px" }}
                className="submit-login cancel-btn mt-2"
              >
                CANCEL
              </Button> */}
              <Button
                variant="contained"
                className="bg-warning nextbutton mt-2"
                onClick={this.handleNext}
                style={{ width: "200px" }}
              >
                Next
              </Button>
              <Button
                variant="contained"
                className="mt-2"
                onClick={this.handleBack}
                style={{ width: "200px" }}
              >
                Back
              </Button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="reportlost-container">
            <div
              className="bag-activation-container"
              style={{ background: "whitesmoke" }}
            >
              <h4>Generic Details</h4>
              <span className="reportlost-container-pet-details-span">
                <span className="reportlost-one-line">
                  <div className="reportlost-container-pet-details-span-input-cnt">
                    {/* <span> *</span> */}

                    <TextField
                      variant="outlined"
                      value={tag_number}
                      label="Owner ID"
                      className="generic-input-pet-details"
                      name="tag_number"
                      InputProps={{ readOnly: true }}
                      // required
                    />
                  </div>

                  <div className="reportlost-container-pet-details-span-input-cnt">
                    {/* <span></span> */}
                    <TextField
                      variant="outlined"
                      value={valuable_type}
                      label="Valuable Type"
                      className="generic-input-pet-details"
                      name="valuable_type"
                      InputProps={{ readOnly: true }}
                      // required
                    />
                  </div>
                </span>
              </span>
              <span className="reportlost-container-pet-details-span mt-3">
                <span className="reportlost-one-line">
                  <div className="reportlost-container-pet-details-span-input-cnt">
                    <span></span>

                    <TextField
                      type="text"
                      id="nextfieldpetname"
                      // className="generic-input-pet-details"
                      InputProps={{ maxLength: 20, readOnly: true }}
                      label="Valuable Description"
                      required
                      name="valuable_desc"
                      value={valuable_desc}
                      onChange={this.changeHandler}
                      variant="outlined"
                    />
                  </div>
                </span>
              </span>
              <span className="reportlost-container-pet-parent-details-span">
                <h4 className="">Generic Owner Details</h4>

                <span className="reportlost-one-line">
                  <div className="reportlost-container-pet-parent-details-span-input-cnt">
                    <TextField
                      label="First Name "
                      type="text"
                      className="generic-input-pet-details"
                      inputProps={{ maxLength: 20, readOnly: true }}
                      required
                      name="owner_firstname"
                      value={owner_firstname}
                      variant="outlined"
                    />
                  </div>

                  <div className="reportlost-container-pet-parent-details-span-input-cnt">
                    <TextField
                      label="Last Name"
                      type="text"
                      className="generic-input-pet-details"
                      inputProps={{ maxLength: 20, readOnly: true }}
                      required
                      name="owner_lastname"
                      value={owner_lastname}
                      variant="outlined"
                    />
                  </div>
                </span>

                <span className="reportlost-one-line">
                  <div className="reportlost-container-pet-parent-details-span-input-cnt">
                    <TextField
                      label="Email ID"
                      type="text"
                      className="generic-input-pet-details"
                      name="parent_email"
                      required
                      value={owner_email}
                      onChange={this.changeHandler}
                      variant="outlined"
                    />
                  </div>

                  <div className="reportlost-container-pet-parent-details-span-input-cnt">
                    <TextField
                      label="Emergency Phone Number 1"
                      type="text"
                      className="generic-input-pet-details"
                      inputProps={{ maxLength: 12 }}
                      name="owner_phone"
                      // required
                      variant="outlined"
                      value={owner_phone}
                      onChange={this.changeHandler}
                    />
                  </div>
                </span>

                <span className="reportlost-one-line">
                  <div className="reportlost-container-pet-parent-details-span-input-cnt">
                    <TextField
                      label="Emergency Phone Number 2"
                      type="text"
                      className="generic-input-pet-details"
                      inputProps={{ maxLength: 12 }}
                      name="owner_altphone"
                      value={owner_altphone}
                      onChange={this.changeHandler}
                      variant="outlined"
                    />
                  </div>
                </span>
              </span>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
              >
                <h4>
                  <i className="fa fa-gift"></i>
                  <span>Owner Reward</span>
                </h4>

                <div className="reportlost-container-currency">
                  <div className="reportlost-container-owner-reward-span-select-cnt">
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ marginTop: "-7px", marginLeft: "13px" }}
                      >
                        Currency type
                      </InputLabel>
                      <Select
                        name="rewardcurrency"
                        value={rewardcurrency}
                        id="reward-id"
                        className="reward-class"
                        labelId="demo-simple-select-label"
                        label="Currency type"
                        onChange={this.changeHandler}
                        variant="outlined"
                      >
                        <MenuItem value="" disabled>
                          Select currency
                        </MenuItem>
                        <MenuItem hidden></MenuItem>
                        <MenuItem value="Rupees">Indian Rupees</MenuItem>
                        <MenuItem value="U.S. dollar">U.S. dollar</MenuItem>
                        <MenuItem value="Euro">Euro</MenuItem>
                        <MenuItem value="Dirham">Dirham</MenuItem>
                        <MenuItem value="Dinar">Dinar</MenuItem>
                        <MenuItem value="Yen">Yen</MenuItem>
                        <MenuItem value="Afghani">Afghani</MenuItem>
                        <MenuItem value="Lek">Lek</MenuItem>
                        <MenuItem value="New Kwanza">New Kwanza</MenuItem>
                        <MenuItem value="Dram">Dram</MenuItem>
                        <MenuItem value="Bahamian dollar">
                          Bahamian dollar
                        </MenuItem>
                        <MenuItem value="Bahrain dinar">Bahrain dinar</MenuItem>
                        <MenuItem value="Taka">Taka</MenuItem>
                        <MenuItem value="Barbados dollar">
                          Barbados dollar
                        </MenuItem>
                        <MenuItem value="Belorussian ruble">
                          Belorussian ruble
                        </MenuItem>
                        <MenuItem value="Belize dollar">Belize dollar</MenuItem>
                        <MenuItem value="Ngultrum">Ngultrum</MenuItem>
                        <MenuItem value="Boliviano">Boliviano</MenuItem>
                        <MenuItem value="Convertible Mark">
                          Convertible Mark
                        </MenuItem>
                        <MenuItem value="Pula">Pula</MenuItem>
                        <MenuItem value="Real">Real</MenuItem>
                        <MenuItem value="Brunei dollar">Brunei dollar</MenuItem>
                        <MenuItem value="Lev">Lev</MenuItem>
                        <MenuItem value="Burundi franc">Burundi franc</MenuItem>
                        <MenuItem value="Riel">Riel</MenuItem>
                        <MenuItem value="Canadian dollar">
                          Canadian dollar
                        </MenuItem>
                        <MenuItem value="Cape Verdean escudo">
                          Cape Verdean escudo
                        </MenuItem>
                        <MenuItem value="Chilean Peso">Chilean Peso</MenuItem>
                        <MenuItem value="Chinese Yuan">Chinese Yuan</MenuItem>
                        <MenuItem value="Colombian Peso">
                          Colombian Peso
                        </MenuItem>
                        <MenuItem value="Franc">Franc</MenuItem>
                        <MenuItem value="United States dollar">
                          United States dollar
                        </MenuItem>
                        <MenuItem value="Colón">Colón</MenuItem>
                        <MenuItem value="Croatian">Croatian</MenuItem>
                        <MenuItem value="Cuban Peso">Cuban Peso</MenuItem>
                        <MenuItem value="Koruna">Koruna</MenuItem>
                        <MenuItem value="Danish Krone">Danish Krone</MenuItem>
                        <MenuItem value="Djiboutian franc">
                          Djiboutian franc
                        </MenuItem>
                        <MenuItem value="Dominican Peso">
                          Dominican Peso
                        </MenuItem>
                        <MenuItem value="Egyptian pound">
                          Egyptian pound
                        </MenuItem>
                        <MenuItem value="Colón; U.S. dollar">
                          Colón; U.S. dollar
                        </MenuItem>
                        <MenuItem value="Nakfa">Nakfa</MenuItem>
                        <MenuItem value="Birr">Birr</MenuItem>
                        <MenuItem value="Fiji dollar">Fiji dollar</MenuItem>
                        <MenuItem value="Dalasi">Dalasi</MenuItem>
                        <MenuItem value="Lari">Lari</MenuItem>
                        <MenuItem value="Cedi">Cedi</MenuItem>
                        <MenuItem value="Quetzal">Quetzal</MenuItem>
                        <MenuItem value="Guinean franc">Guinean franc</MenuItem>
                        <MenuItem value="Guyanese dollar">
                          Guyanese dollar
                        </MenuItem>
                        <MenuItem value="Gourde">Gourde</MenuItem>
                        <MenuItem value="Lempira">Lempira</MenuItem>
                        <MenuItem value="Forint">Forint</MenuItem>
                        <MenuItem value="Icelandic króna">
                          Icelandic króna
                        </MenuItem>
                        <MenuItem value="Rupiah">Rupiah</MenuItem>
                        <MenuItem value="Iraqi Dinar">Iraqi Dinar</MenuItem>
                        <MenuItem value="Shekel">Shekel</MenuItem>
                        <MenuItem value="Jamaican dollar">
                          Jamaican dollar
                        </MenuItem>
                        <MenuItem value="Jordanian dinar">
                          Jordanian dinar
                        </MenuItem>
                        <MenuItem value="Tenge">Tenge</MenuItem>
                        <MenuItem value="Kenya shilling">
                          Kenya shilling
                        </MenuItem>
                        <MenuItem value="Kiribati dollar">
                          Kiribati dollar
                        </MenuItem>
                        <MenuItem value="Won">Won</MenuItem>
                        <MenuItem value="Kuwaiti Dinar">Kuwaiti Dinar</MenuItem>
                        <MenuItem value="Som">Som</MenuItem>
                        <MenuItem value="New Kip">New Kip</MenuItem>
                        <MenuItem value="Lats">Lats</MenuItem>
                        <MenuItem value="Lebanese pound">
                          Lebanese pound
                        </MenuItem>
                        <MenuItem value="Maluti">Maluti</MenuItem>
                        <MenuItem value="Liberian dollar">
                          Liberian dollar
                        </MenuItem>
                        <MenuItem value="Libyan dinar">Libyan dinar</MenuItem>
                        <MenuItem value="Litas">Litas</MenuItem>
                        <MenuItem value="Denar">Denar</MenuItem>
                        <MenuItem value="Malagasy Ariary">
                          Malagasy Ariary
                        </MenuItem>
                        <MenuItem value="Ringgit">Ringgit</MenuItem>
                        <MenuItem value="Rufiyaa">Rufiyaa</MenuItem>
                        <MenuItem value="Ouguiya">Ouguiya</MenuItem>
                        <MenuItem value="Mauritian rupee">
                          Mauritian rupee
                        </MenuItem>
                        <MenuItem value="Mexican peso">Mexican peso</MenuItem>
                        <MenuItem value="Leu">Leu</MenuItem>
                        <MenuItem value="Togrog">Togrog</MenuItem>
                        <MenuItem value="Metical">Metical</MenuItem>
                        <MenuItem value="Kyat">Kyat</MenuItem>
                        <MenuItem value="Namibian dollar">
                          Namibian dollar
                        </MenuItem>
                        <MenuItem value="Australian dollar">
                          Australian dollar
                        </MenuItem>
                        <MenuItem value="Nepalese rupee">
                          Nepalese rupee
                        </MenuItem>
                        <MenuItem value="New Zealand dollar">
                          New Zealand dollar
                        </MenuItem>
                        <MenuItem value="Gold cordoba">Gold cordoba</MenuItem>
                        <MenuItem value="Naira">Naira</MenuItem>
                        <MenuItem value="Norwegian krone">
                          Norwegian krone
                        </MenuItem>
                        <MenuItem value="Omani rial">Omani rial</MenuItem>
                        <MenuItem value="Pakistani rupee">
                          Pakistani rupee
                        </MenuItem>
                        <MenuItem value="Palestine Pound">
                          Palestine Pound
                        </MenuItem>
                        <MenuItem value="Balboa; U.S. dollar">
                          Balboa; U.S. dollar
                        </MenuItem>
                        <MenuItem value="Kina">Kina</MenuItem>
                        <MenuItem value="Guaraní">Guaraní</MenuItem>
                        <MenuItem value="Nuevo sol (1991)">
                          Nuevo sol (1991)
                        </MenuItem>
                        <MenuItem value="Peso">Peso</MenuItem>
                        <MenuItem value="Zloty">Zloty</MenuItem>
                        <MenuItem value="Qatari riyal">Qatari riyal</MenuItem>
                        <MenuItem value="Romanian Rupee">
                          Romanian Rupee
                        </MenuItem>
                        <MenuItem value="Ruble">Ruble</MenuItem>
                        <MenuItem value="Rwandan franc">Rwandan franc</MenuItem>
                        <MenuItem value="East Caribbean dollar">
                          {" "}
                          East Caribbean dollar
                        </MenuItem>
                        <MenuItem value="Tala">Tala</MenuItem>
                        <MenuItem value="Dobra">Dobra</MenuItem>
                        <MenuItem value="Riyal">Riyal</MenuItem>
                        <MenuItem value="Serbian Dinar">Serbian Dinar</MenuItem>
                        <MenuItem value="Seychelles rupee">
                          Seychelles rupee
                        </MenuItem>
                        <MenuItem value="Leone">Leone</MenuItem>
                        <MenuItem value="Singapore dollar">
                          Singapore dollar
                        </MenuItem>
                        <MenuItem value="Solomon Islands dollar">
                          Solomon Islands dollar
                        </MenuItem>
                        <MenuItem value="Somali shilling">
                          Somali shilling
                        </MenuItem>
                        <MenuItem value="Rand">Rand</MenuItem>
                        <MenuItem value="Sri Lankan rupee">
                          Sri Lankan rupee
                        </MenuItem>
                        <MenuItem value="Sudanese Pound">
                          Sudanese Pound
                        </MenuItem>
                        <MenuItem value="Surinamese dollar">
                          Surinamese dollar
                        </MenuItem>
                        <MenuItem value="Lilangeni">Lilangeni</MenuItem>
                        <MenuItem value="Krona">Krona</MenuItem>
                        <MenuItem value="Swiss franc">Swiss franc</MenuItem>
                        <MenuItem value="Syrian pound">Syrian pound</MenuItem>
                        <MenuItem value="Taiwan dollar">Taiwan dollar</MenuItem>
                        <MenuItem value="somoni">somoni</MenuItem>
                        <MenuItem value="Tanzanian shilling">
                          Tanzanian shilling
                        </MenuItem>
                        <MenuItem value="Baht">Baht</MenuItem>
                        <MenuItem value="CFA Franc">CFA Franc</MenuItem>
                        <MenuItem value="Pa’anga">Pa’anga</MenuItem>
                        <MenuItem value="Trinidad and Tobago dollar">
                          Trinidad and Tobago dollar
                        </MenuItem>
                        <MenuItem value="Tunisian dinar">
                          Tunisian dinar
                        </MenuItem>
                        <MenuItem value="Turkish lira (YTL)">
                          Turkish lira (YTL)
                        </MenuItem>
                        <MenuItem value="Manat">Manat</MenuItem>
                        <MenuItem value="Tuvaluan Dollar">
                          Tuvaluan Dollar
                        </MenuItem>
                        <MenuItem value="Ugandan new shilling">
                          Ugandan new shilling
                        </MenuItem>
                        <MenuItem value="Hryvnia">Hryvnia</MenuItem>
                        <MenuItem value="U.A.E. Dirham">U.A.E. Dirham</MenuItem>
                        <MenuItem value="Pound sterling">
                          Pound sterling
                        </MenuItem>
                        <MenuItem value="Dollar">Dollar</MenuItem>
                        <MenuItem value="Uruguay peso">Uruguay peso</MenuItem>
                        <MenuItem value="Uzbekistani sum">
                          Uzbekistani sum
                        </MenuItem>
                        <MenuItem value="Vatu">Vatu</MenuItem>
                        <MenuItem value="Bolivar">Bolivar</MenuItem>
                        <MenuItem value="Dong">Dong</MenuItem>
                        <MenuItem value="Rial">Rial</MenuItem>
                        <MenuItem value="Kwacha">Kwacha</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="reportlost-container-owner-reward-span-select-cnt">
                    <TextField
                      type="number"
                      name="reward"
                      variant="outlined"
                      label="reward"
                      value={reward}
                      onChange={this.changeHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="reportlost-container-owner-reward-btns">
                <Button variant="contained" onClick={this.handleBack}>
                  Back
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  style={{ backgroundColor: "#ffc107" }}
                >
                  Submit
                </Button>

                <Button
                  type="button"
                  onClick={() => {
                    window.location.href =
                      "/dashboard/" + localStorage.getItem("uid");
                  }}
                  variant="contained"
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        );
    }
  };

  handleNext = () => {
    this.setState((prevActiveStep) => {
      console.log("prev", prevActiveStep);
      return {
        activeStep: prevActiveStep.activeStep + 1,
      };
    });
  };

  handleBack = () => {
    this.setState((prevActiveStep) => {
      return {
        activeStep: prevActiveStep.activeStep - 1,
      };
    });
  };
  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }
  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  render() {
    const {
      sidebar,
      subMenu,
      alignment,
      tag_number,
      valuable_type,
      owner_firstname,
      owner_lastname,
      owner_phone,
      owner_altphone,
      owner_email,
      owner_altemail,
      reward,
      rewardcurrency,
    } = this.state;

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <Navbar />

        <div className="genericreportLost-main-wrapper">
          <div className="genericreportLost-left-wrapper">
            <div className="genericreportLost-sidebar-toggle-btn-wrapper">
              <button
                className="genericreportLost-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "genericreportLost-sidebar-container-hide"
                  : "genericreportLost-sidebar-container"
              }
            >
              <button
                className="genericreportLost-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "genericreportLost-sidebar-menu-ul-hide"
                    : "genericreportLost-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="genericreportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="genericreportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="genericreportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

              <button
                className="genericreportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + this.props.match.params.tag_number;
                }}
              >
                ACTIVATE TAG
              </button>

              {/* <button
              className="genericreportLost-sidebar-menu-link"
              onClick={() => {
                window.location.href =
                  "/#/calendar/" + this.props.match.params.tag_number;
              }}
            >
              CALENDAR
            </button> */}

              <button
                className="genericreportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/scan-notification/" + localStorage.getItem("uid");
                }}
              >
                NOTIFICATIONS
              </button>
            </div>
          </div>
          <div
            className="reportlost-bag-container"
            style={this.getContainerStyle()}
          >
            <div className="background-image">
              <div className="bag-activation-container">
                <form onSubmit={this.submitHandler}>
                  <Stepper
                    activeStep={this.state.activeStep}
                    alternativeLabel
                    className=""
                    style={{ background: "whitesmoke" }}
                  >
                    {steps.map((label) => (
                      <Step key={label} style={{ color: "whitesmoke" }}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  <div
                    className="container uppercontainer bg-white px-0 p-info text-center genericreportlostheight"
                    style={{
                      // height: this.state.activeStep === 3 ? "100%" : "570px",
                      backgroundColor: "whitesmoke",
                    }}
                  >
                    {this.getStepContent(this.state.activeStep)}
                  </div>

                  {this.state.showLoader && (
                    <div className="loader-container">
                      <div className="loader">
                        <img
                          src={Loader}
                          alt="Loading..."
                          loading="lazy"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  )}

                  <Snackbar
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={this.state.snackbarmsg}
                    action={[
                      <IconButton
                        arial-label="Close"
                        color="inherit"
                        onClick={this.snackbarClose}
                      >
                        X
                      </IconButton>,
                    ]}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
