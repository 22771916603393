import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
// import Logo from "../../assets/Tag8logofinal.png";
import "./header.component.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import jwt_decode from "jwt-decode";
import Button from "@material-ui/core/Button";
// import UserImage from "../../assets/user2.png";
import * as BiIcons from "react-icons/bi";
// import qrcode from "../../assets/qr-code.png";
import { QrReader } from "react-qr-reader";
import { switchUrls } from "../../api/index";

const baseURL = switchUrls("gateway");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

const delete_cookie = (name) => {
  const domain = window.location.hostname.includes("localhost")
    ? "localhost"
    : window.location.hostname.includes("tag8.co.in")
    ? ".tag8.co.in"
    : window.location.hostname.includes("tag8.in")
    ? ".tag8.in"
    : "";

  if (domain) {
    document.cookie = `${name}=; Path=/; domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  } else {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
};

function Navbar() {
  const token = usertoken;
  const [isAuth, setIsAuth] = useState(usertoken !== null ? true : false);
  const [passwordType, setPasswordType] = useState("password");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [form, setForm] = useState("none");
  const [form1, setForm1] = useState("none");
  const [firstName, setFirstName] = useState(" ");
  const [lastName, setLastName] = useState(" ");
  const [uid, setUID] = useState("");
  const [navbar, setNavbar] = useState(false);
  const [ownerID, setOwnerID] = useState("");
  const [tagNotFound, setTagNotFound] = useState(false);
  const [alreadyActivated, setAlreadyActivated] = useState(false);
  const handleNotFound = () => setTagNotFound(false);
  const handleActivated = () => setAlreadyActivated(false);
  const [changePassword, setChangePassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [characterLength, setCharacterLength] = useState("none");
  const [validPassword, setValidPassword] = useState("none");
  const [passwordValid, setPasswordValid] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("none");
  const [show, setShow] = useState(false);
  const [show5, setShow5] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const passwordRE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }
  const renderQRModal = () => {
    return (
      <Dialog
        open={show5}
        fullWidth
        maxWidth="xs"
        onClose={() => setShow5(false)}
        aria-labelledby={"Scan QR"}
      >
        <div>
          <QrReader
            onResult={(result, error) => {
              if (result) {
                setOwnerID(
                  result?.text.split("/")[result?.text.split("/").length - 1]
                );
                setShow5(false);
              }

              if (error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </div>
      </Dialog>
    );
  };

  function activateTag() {
    const state = {
      tag_number: ownerID,
    };
    axios
      .post(`${baseURL}/api/checkTagNumberWithoutHeader`, state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data === "Tag not Found by the specified Number") {
          setTagNotFound(true);
        } else if (res.data === "Tag is already activated") {
          setAlreadyActivated(true);
        } else {
          const initial = ownerID.substring(0, 3);
          if (initial === "PET") {
            window.location.href = "/pet-activation/" + ownerID;
          } else if (initial === "KEY") {
            window.location.href = "/key-activation/" + ownerID;
          }
          if (initial === "TGZ") {
            window.location.href = "/pet-activation/" + ownerID;
          } else if (initial === "TGY") {
            window.location.href = "/key-activation/" + ownerID;
          } else if (initial === "PAS") {
            window.location.href = "/passport-activation/" + ownerID;
          } else if (initial === "GEN") {
            window.location.href = "/generic-activation/" + ownerID;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function reportFound() {
    window.location.href = "/scan/" + ownerID;
  }
  function logout(event) {
    delete_cookie("token");
    window.location.href = `${baseURL}/#/login`;
    setIsAuth(false);
  }
  function updatePassword() {
    const state = {
      password: password,
      email: email,
    };
    axios
      .post("/api/updatePassword", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.data === "Password updated successfully") {
          setChangePassword(false);
          setMessage("Password updated succesfully");
          setAlert("flex");
          setPassword("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    // console.log(isAuth);
    if (isAuth) {
      axios
        .get(`${baseURL}/api/getUserProfile`, {
          headers: {
            Authorization: usertoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res);
          localStorage.setItem("uid", res.data.uid);
          setUID(res.data.uid);
          localStorage.setItem(
            "name",
            res.data.first_name + " " + res.data.last_name
          );
          setFirstName(res.data.first_name);
          setLastName(res.data.last_name);
          setEmail(res.data.email);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  function formValid() {
    return passwordValid;
  }
  useEffect(() => {
    if (password.length > 7 || password === "") {
      setCharacterLength("none");
      if (passwordRE.test(password)) {
        setPasswordValid(true);
        setValidPassword("none");
      } else if (password !== "") {
        setPasswordValid(false);
        setValidPassword("block");
      } else {
        setPasswordValid(false);
        setValidPassword("none");
      }
    } else {
      setValidPassword("block");
      setCharacterLength("block");
      setPasswordValid(false);
    }
    setSubmitDisabled(!formValid());
  });
  const valid = (item) => {
    let password = document.querySelector(`#${item}`);
    password.style.display = "none";
  };
  //If Form Not Valid
  const invalid = (item) => {
    let password = document.querySelector(`#${item}`);
    password.style.display = "block";
  };
  useEffect(() => {
    if (alert === "flex") {
      setTimeout(() => setAlert("none"), 8000);
    }
  });

  useEffect(() => {
    if (usertoken) {
      setInterval(() => {
        var decoded = jwt_decode(usertoken);
        const expiry = decoded.exp * 1000 - 60000;
        if (Date.now() >= expiry) {
          delete_cookie("token");
          window.location.href = "/passport";
        }
      }, 1000);
    }
  });

  const showNavbar = (e) => {
    e.preventDefault();
    setNavbar(!navbar);
  };
  return (
    <header className="genericheader-section">
      <a className="logo" href="/">
        <img
          src={
            "https://storage.googleapis.com/pettag/qr/assets/Tag8-Logo-slst--black.png"
          }
          className="logo-image"
        />
      </a>

      <button
        className="genericnavbar-toggle-btn"
        aria-controls="navbarSupportedContent"
        aria-label="Toggle navigation"
        onClick={showNavbar}
      >
        {navbar ? <BiIcons.BiMenuAltRight /> : <BiIcons.BiMenuAltLeft />}
      </button>

      <nav
        className={navbar ? "genericheader-nav" : "genericheader-nav-hide"}
        id="navbarSupportedContent"
      >
        <ul className="genericheader-nav-ul">
          <li className="genericheader-nav-ul-li">
            <a href="/" className=" genericnavbar__options">
              Home
            </a>
          </li>

          <li className="genericheader-nav-ul-li">
            <a href="/#/faq" className=" genericnavbar__options">
              FAQ
            </a>
          </li>

          <li className="genericheader-nav-ul-li">
            <a
              className=" genericnavbar__options"
              target="_blank"
              href="https://tag8.in/tag8-store-new/public/products/genieSmartTag.php"
            >
              Store
            </a>
          </li>

          <li className="genericheader-nav-ul-li">
            {!isAuth || firstName === "" ? (
              <a className=" genericnavbar__options" href="/#/login">
                Login
              </a>
            ) : (
              <a className=" genericnavbar__options" href={`/dashboard/` + uid}>
                My Tags
              </a>
            )}
          </li>

          <li className="genericheader-nav-ul-li">
            {!isAuth || firstName === "" ? (
              <a className=" genericnavbar__options" href="/signup">
                Signup
              </a>
            ) : (
              <div className="dropdown hide-on-responsive ">
                <Link to="#" className="dropbtn genericnavbar__options">
                  <span>
                    <img
                      src={"https://storage.googleapis.com/pettag/qr/user2.png"}
                      alt="user"
                      className="user-image"
                    />
                    {capitalizeName(firstName + " " + lastName)}
                    <i className="fa fa-caret-down ml-2" aria-hidden="true"></i>
                  </span>
                </Link>

                <div className="genericdropdown-content-1">
                  <a href="/profile" className="dropdown-item">
                    <center className="drop_down_nav">Profile</center>
                  </a>

                  {localStorage.getItem("logintype") === "manual" ? (
                    <Link
                      to="#"
                      onClick={() => setChangePassword(true)}
                      className="dropdown-item"
                    >
                      <center className="drop_down_nav">Change Password</center>
                    </Link>
                  ) : (
                    ""
                  )}

                  <a
                    onClick={logout}
                    href={`${baseURL}/#/login`}
                    className="dropdown-item"
                  >
                    <center className="drop_down_nav">Logout</center>
                  </a>
                </div>
              </div>
            )}
          </li>

          <li className="genericheader-nav-ul-li">
            {/* <div className="got-genie-form" style={{ display: form }}>
              <input
                style={{ padding: "-5px" }}
                placeholder="Owner ID"
                onKeyUp={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                }}
                onChange={(e) => setOwnerID(e.target.value)}
              /> */}

            {/* <div className="genie-form-buttons">
                <Button
                  variant="light"
                  title={
                    !isAuth ? "Please login to activate tag" : "Activate Tag"
                  }
                  onClick={activateTag}
                  disabled={!isAuth}
                >
                  Activate Tag
                </Button>

                <Button variant="light" onClick={reportFound}>
                  Report Found
                </Button>
              </div> */}
            {/* </div> */}

            <button className="got-genie1" onClick={handleShow}>
              <span className="got-genie-top font-weight-bold">Click To</span>
              <br></br> Report Found
            </button>
          </li>
        </ul>

        <div className="got-generic-div-1">
          <button className="got-genie1" onClick={handleShow}>
            <span className="got-genie-top font-weight-bold">Click To</span>
            <br></br> Report Found
          </button>
          {/* <div className="got-genie-form" style={{ display: form }}>
            <input
              style={{ padding: "-5px" }}
              placeholder="Owner ID"
              onKeyUp={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              onChange={(e) => setOwnerID(e.target.value)}
            ></input>

            <img
              src={qrcode}
              className="qrbutton"
              onClick={(e) => setShow5(true)}
            />

            {/* <div className="genie-form-buttons">
              <Button
                variant="light"
                title={
                  !isAuth ? "Please login to activate tag" : "Activate Tag"
                }
                onClick={activateTag}
                disabled={!isAuth}
              >
                Activate Tag
              </Button>

              <Button variant="light" onClick={reportFound}>
                Report Found
              </Button>
            </div> */}
          {/* </div> */}
        </div>
      </nav>

      <Dialog
        disableBackdropClick
        open={tagNotFound}
        onClose={handleNotFound}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="found-notfound-popup">
          <h1
            style={{
              margin: "20px",
              font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
              letterSpacing: "normal",
            }}
          >
            Tag Number Not Found
          </h1>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              className="otp-buttons"
              style={{ margin: "10px 20px", marginBottom: "50px" }}
              onClick={handleNotFound}
            >
              OK
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        disableBackdropClick
        open={alreadyActivated}
        onClose={handleActivated}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="found-notfound-popup">
          <h1
            style={{
              margin: "20px",
              font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
              letterSpacing: "normal",
            }}
          >
            Tag is already activated
          </h1>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              className="otp-buttons"
              style={{ margin: "10px 20px", marginBottom: "50px" }}
              onClick={handleActivated}
            >
              OK
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        disableBackdropClick
        maxWidth="xs"
        open={changePassword}
        onClose={() => setChangePassword(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent
          className="change_password_div"
          style={{ width: "100%" }}
        >
          <h1
            style={{
              margin: "20px",
              font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
              letterSpacing: "normal",
            }}
          >
            Change Password
          </h1>

          <div
            className="mb-4 inner-change-pwd-div"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <input
              className="pl-1 new_email input-fd input__field__dialogs"
              style={{
                margin: "20px",
                height: "35px",
                width: "350px",
                borderRadius: "10px",
              }}
              value={email}
              readOnly
            ></input>

            <input
              className="pl-1 new_pwd input-fd input__field__dialogs"
              style={{
                margin: "20px",
                height: "35px",
                width: "350px",
                borderRadius: "10px",
              }}
              type={passwordType}
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);

                const txt = e.target.value;

                if (txt.length >= 8) {
                  valid("islen");
                } else {
                  invalid("islen");
                }

                if (txt.match(/[A-Z]/) != null) {
                  valid("capital");
                } else {
                  invalid("capital");
                }

                if (txt.match(/[a-z]/) != null) {
                  valid("small");
                } else {
                  invalid("small");
                }

                if (txt.match(/[0-9]/) != null) {
                  valid("isnum");
                } else {
                  invalid("isnum");
                }
              }}
              required
            />
            <div style={{ paddingLeft: "22px", fontSize: "14px" }}>
              <span style={{ display: characterLength }} id="islen">
                Password must be atleat 8 characters
              </span>

              <span style={{ display: validPassword }} id="issp">
                Password must contain -{" "}
              </span>

              <span style={{ display: validPassword }} id="isnum">
                Atleast one number
              </span>

              <span style={{ display: validPassword }} id="capital">
                Atleast one uppercase letter
              </span>

              <span style={{ display: validPassword }} id="small">
                Atleast one lowercase letter
              </span>
            </div>

            <div
              className="ml-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                className="show_pwd"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginTop: "-11px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
                onClick={() =>
                  setPasswordType(
                    passwordType === "password" ? "text" : "password"
                  )
                }
              ></input>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  paddingTop: "5px",
                }}
              >
                Show Password
              </p>
            </div>

            <button
              className="verifyOtp-button"
              disabled={submitDisabled}
              style={{ margin: "10px 80px" }}
              onClick={updatePassword}
            >
              Submit
            </button>

            <button
              className="otp-buttons"
              style={{ margin: "10px 80px" }}
              onClick={() => {
                setChangePassword(false);
                setEmail("");
                setPassword("");
              }}
            >
              Cancel
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        disableBackdropClick
        open={show}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <h2 className="header-activateTag-popup">Tag Activation</h2>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              className="header-input__field__dialogs"
              onKeyUp={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              value={ownerID ? ownerID : ""}
              onChange={(e) => setOwnerID(e.target.value)}
              placeholder="Owner ID"
            />

            <img
              src={"https://storage.googleapis.com/pettag/qr/qr-code.png"}
              className="qrbutton"
              onClick={(e) => setShow5(true)}
            />
          </div>

          <div
            style={{
              padding: "1em",
              marginBottom: "1px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <button className="header-verifyOtp-button" onClick={activateTag}>
              Activate
            </button>

            <button className="header-otp-buttons" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <div className="AlertSignup" style={{ display: alert }}>
        <p>{message}</p>
        <button onClick={() => setAlert("none")}>Cancel</button>
      </div>

      {renderQRModal()}
    </header>
  );
}

export default Navbar;
